import { createGlobalStyle } from 'styled-components';
import 'styles/font/Helvetica Neu Bold.ttf';
import 'styles/font/NotoSans-Black.ttf';
import 'styles/font/NotoSans-BlackItalic.ttf';
import 'styles/font/NotoSans-Bold.ttf';
import 'styles/font/NotoSans-BoldItalic.ttf';
import 'styles/font/NotoSans-ExtraBold.ttf';
import 'styles/font/NotoSans-ExtraBoldItalic.ttf';
import 'styles/font/NotoSans-ExtraLight.ttf';
import 'styles/font/NotoSans-ExtraLightItalic.ttf';
import 'styles/font/NotoSans-Italic.ttf';
import 'styles/font/NotoSans-Light.ttf';
import 'styles/font/NotoSans-LightItalic.ttf';
import 'styles/font/NotoSans-Medium.ttf';
import 'styles/font/NotoSans-MediumItalic.ttf';
import 'styles/font/NotoSans-Regular.ttf';
import 'styles/font/NotoSans-SemiBold.ttf';
import 'styles/font/NotoSans-SemiBoldItalic.ttf';
import 'styles/font/NotoSans-Thin.ttf';
import 'styles/font/NotoSans-ThinItalic.ttf';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    scroll-behavior: smooth;
  }
  
  :root {
    --color-primary: #C6A785;
    --color-secondary: #F4887B;
    --color-warning: #E10000;
    --color-black: #1F2937;
    --color-focus: #FFF2F1;
    --color-white: #FFFFFF;
    --color-gray-1: #707070;
    --color-gray-2: #919191;
    --color-gray-3: #D8D8D8;
    --color-gray-4: #EDEDED;
    --color-gray-5: #F8F8F8;
    --color-gray-6: #6E6969;
    --color-gray-7: #BEBEBE;
    --color-btn-hover: #957C63;
    --color-orange: #EC895E;
    --color-green: #89CB72;

  }

  body, p, h1, h2, h3, h4 , h5, label, span, a,div {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0px;
  }

  a{
    cursor: pointer;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  input, select, button {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: inherit;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .container-custom{
    padding-left: 360px;
    padding-right: 360px;
    padding-bottom: 40px;
    @media only screen and (max-width: 1440px) {
      padding-left: 120px;
      padding-right: 120px;
    }
    @media only screen and (max-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .ellipsis-1 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .ellipsis-2 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .ellipsis-5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .ellipsis-7 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .loading-data-wrapper {
    z-index: 9;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;
    position: fixed;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::placeholder {
    opacity: 1 !important;
  }

  .ant-form-item-explain-error {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #e10000;
    font-size: 14px;
    width: fit-content;
  }


  .ant-form-item-required {
    &::before {
      color: #e10000 !important;
      font-weight: bold !important;
      font-size: 15px;
      margin-inline-end: 4px;
      content: "*";
      font-family: 'Noto Sans JP', serif !important;
    }

    &::after {
      font-weight: bold !important;
      font-size: 15px;
      content: ":";
    }
  }
  .ant-input-disabled {
    color: var(--color-gray-2) !important;
    background: var(--color-gray-4) !important;
  }
  .ant-tag {
    border-radius: 10px;
    padding: 0px 15px;
    margin-inline-end: 0px;
    min-width: 81px;
    text-align: center;
  }
 
  .ant-input-number-input {
    padding: 0;
  }

  .ant-spin-nested-loading >div>.ant-spin{
    max-height: 100%;
  }

  .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
  }

  .ant-dropdown-menu {
    width: max-content;
    margin-left: auto !important;
  }

  .btn-text.ant-btn {
    padding: 0px;
    width: 100%;
    text-align: left;
    &:hover {
      background: transparent;
    }
  }

  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
    }

  .ant-form-item .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
  }

  .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
    color: #9ca3af;
    font-weight: 400;
  }
  
  .ant-form-item-explain-error {
    color: #e10000 !important;
  }

  .ant-form-item-explain-error:first-child {
    display: block;
  }

  .ant-switch.ant-switch-checked, .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #EB7D17;
  }

  .ant-tabs-dropdown-menu-item-remove{
    display: none;
  }
  

  .ant-tabs-dropdown-menu-item{
    .tab-text-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    strong {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mb-0{
    margin-bottom: 0px;
  }

  .aspect-ratio {
    margin: 0 auto;
  }

  [style*="--aspect-ratio"] > :first-child {
  width: 100%;
  }

  [style*="--aspect-ratio"] > img {
    height: auto;
  }

  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
  }

  @supports not (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }
  @supports (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      aspect-ratio: calc(var(--aspect-ratio));
    }
  }
`;
