/**
 *
 * MetaTitle
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props {
  text: string;
  subText?: string;
}

export const MetaTitle = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { text, subText } = props;

  return (
    <div>
      {text && <H1>{text}</H1>} {subText && <H4>{subText}</H4>}
    </div>
  );
});

const H1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  text-align: center;
  color: #1f2937;
  margin-bottom: 0;
`;

const H4 = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #9ca3af;
  margin: 0;
`;
