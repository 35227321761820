import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';
import styled from 'styled-components/macro';
import { Header } from './Header/Loadable';
import { SideBar } from './SideBar/Loadable';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);

const MainLayout = ({ children }) => {
  return (
    <LayoutGlobal>
      <Header />
      <div className="box-content">
        <SideBar />
        <div className="content">{children}</div>
      </div>
    </LayoutGlobal>
  );
};
const LayoutGlobal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  .box-content {
    display: grid;
    grid-template-columns: 1fr;
    margin: 17px 20px 17px 0px;
    min-height: calc(100vh - 94px);
    .content {
      background: #f3f4f6;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #d1d5db;
      width: calc(100vw - 300px);
      margin-left: auto;
    }
  }
`;

export default MainLayout;
