import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 35% calc(100% - 35%);
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    grid-template-columns: 400px calc(100% - 400px);
  }
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    background: #0000003d;
  }
  @media only screen and (max-width: 568px) {
    grid-template-columns: 1fr;
    background: #0000003d;
    padding: 0px 20px;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .form-content {
    max-width: 328px;
    width: 100%;
    margin: auto;
    justify-content: flex-start;
    margin-top: 200px;
    height: max-content;
    @media only screen and (max-width: 1440px) {
      margin: auto;
    }
    @media only screen and (max-width: 992px) {
      max-width: 500px;
      background: #fff;
      padding: 20px;
      border-radius: 20px;
    }
  }

  .form-login {
    width: 100%;
  }

  .forgot-pass {
    a {
      position: absolute;
      right: 0;
      bottom: -25px;
      color: #9ca3af;
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
      &:hover {
        color: #eb7d17;
      }
    }
  }

  .ant-btn-primary {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    width: fit-content;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TabBox = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

export const CarouseBox = styled.div`
  @media only screen and (max-width: 992px) {
    display: none;
  }
  div {
    height: 100%;
  }
  .ant-carousel:focus-visible {
    outline: none;
  }
  .ant-carousel,
  .ant-carousel .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }
  .slick-dots {
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-inline: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    li.slick-active {
      width: 18px;
      height: 18px;
    }
  }
`;
