import { Form } from 'antd';
import { MetaButton, MetaInput } from 'app/components/Forms';
import React from 'react';
import styled from 'styled-components/macro';
import { regexEmail } from 'utils/helpers';

const LoginForm = ({ form, onFinish }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="form-login"
    >
      <MetaInput
        isNormalize
        name="email"
        label={
          <p>
            Email address <b style={{ color: '#c0392b' }}>*</b>
          </p>
        }
        placeholder="Enter Email address"
        rules={[
          { required: true, message: 'Please enter Email address' },
          {
            pattern: regexEmail,
            message: 'Invalid Email address',
          },
        ]}
        form={form}
      />

      <BoxRelative>
        <MetaInput
          form={form}
          label={
            <p>
              Password <b style={{ color: '#c0392b' }}>*</b>
            </p>
          }
          type="password"
          name="password"
          placeholder="Enter Password"
          className="input-password"
          rules={[{ required: true, message: 'Please enter Password' }]}
        />
      </BoxRelative>
      <Form.Item
        style={{ textAlign: 'center', lineHeight: 2, paddingTop: '10px' }}
      >
        <MetaButton type="primary" htmlType="submit" text="Sign me in" />
      </Form.Item>
    </Form>
  );
};

export default LoginForm;

const BoxRelative = styled.div`
  position: relative;
`;
