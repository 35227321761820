import { Form } from 'antd';
import styled from 'styled-components';

export const UploadFormItem = styled(Form.Item)<any>`
  width: 100%;
  margin-bottom: 10px;
  .ant-form-item-label {
    align-items: flex-start !important;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #9ca3af;
      font-weight: 400;
    }
  }
  label {
    font-size: 14px;
    color: #9ca3af;
    font-weight: 400;
    &::after {
      content: ${props => (props.label === ' ' ? '" "' : '":"')} !important;
    }
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #9ca3af;
      font-weight: 400;
    }
  }
  .ant-form-item-explain-error {
    color: #e10000 !important;
  }
  .ant-form-item-explain-error:first-child {
    display: block;
  }

  .ant-input-status-error,
  .ant-input-affix-wrapper-status-error {
    border-color: #e10000 !important;
  }

  .upload-content {
    border: 1px solid #d1d5db;
    height: 78px;
    border-radius: 8px;
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    /* padding: 0px 20px; */
    justify-content: space-between;
    gap: 10px;
    a {
      color: #eb7d17;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .anticon {
      font-size: 12px;
      color: #eb7d17;
      cursor: pointer;
    }

    .upload-display {
      /* display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;
      max-width: 50%; */
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: fit-content;
      height: fit-content;
    }
  }

  .upload-content-box {
    /* display: flex; */
    /* flex-direction: column; */
    /* min-width: 175px; */
    position: absolute;
    inset: 0;
    padding: 0 20px;
    height: 100%;
    width: 100%;
  }

  .upload-field-content,
  .ant-upload.ant-upload-select,
  .ant-upload {
    position: absolute;
    inset: 0;
    padding: 0 20px;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .effect-audio {
    width: 175px;

    &::-webkit-media-controls-panel {
      -webkit-justify-content: center;
    }

    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display,
    &::-webkit-media-controls-timeline,
    &::-webkit-media-controls-time-remaining-display {
      display: none;
      background-color: transparent !important;
    }
  }
`;
