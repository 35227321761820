export const PATH = {
  HOME: '/',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',

  GENERAL: '/general',
  BALANCE: '/balance',
  POINT: '/point',
  ACTIVITY: '/activity',
  GIFT: '/gift',
  ANALYTIC: '/analytic',
  UNITY: '/unity',
  SETTING_TIME: '/setting-time',
  AVATARS: '/avatars',

  RELOAD: '/reload',
  ERROR_404: '/error-404',
  ERROR_500: '/error-500',
  ERROR_503: '/error-503',
  PAGE_NOT_FOUND: '*',
};

export const ROLE = {
  ADMIN: '0',
  TALENT: '1',
  GENERAL_USER: '2',
};
