import React from 'react';
import { useUnityContext, Unity } from 'react-unity-webgl';
import { UnityCacheControlMode } from 'react-unity-webgl/distribution/types/unity-cache-control-mode';
import Cookies from 'js-cookie';

import { PATH } from 'app/constants/path';
import { IsIOS } from 'utils/common';
import useInit from './hooks/useInit';

const UnityPage = () => {
  const {} = useInit();

  let unity_root = '/SourceUnity/mobile/';
  globalThis.url3davatar = process.env.REACT_APP_3DURL;
  globalThis.environment = process.env.REACT_APP_ENVIRONMENT;
  globalThis.streamlist = '[]';
  globalThis.apiurl = process.env.REACT_APP_API_URL;
  globalThis.socketurl = process.env.REACT_APP_SOCKET_API_URL;
  globalThis.domainurl = process.env.REACT_APP_DOMAIN;

  globalThis.isMobile =
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
    (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /Macintosh/.test(navigator.userAgent));

  function handleCacheControl(url: string): UnityCacheControlMode {
    if (
      (url.match(/\.data/) ||
        url.match(/\.framework.js/) ||
        url.match(/\.loader.js/) ||
        url.match(/\.wasm.unityweb/) ||
        url.match(/\.glb/) ||
        url.includes('/AssetsBundles/')) &&
      !url.includes('_custom_avatar_')
    ) {
      return 'must-revalidate';
    } else {
      return 'no-store';
    }
  }

  // Select source Unity base on current device
  if (globalThis.isMobile) {
    unity_root = '/SourceUnity/mobile/';
  } else {
    unity_root = '/SourceUnity/pc/';
  }

  const {
    unityProvider,
    isLoaded,
    addEventListener,
    removeEventListener,
    sendMessage,
    UNSAFE__unityInstance,
  } = useUnityContext({
    loaderUrl: unity_root + 'WebGL.loader.js',
    dataUrl: unity_root + 'WebGL.data.unityweb',
    frameworkUrl: unity_root + 'WebGL.framework.js.unityweb',
    codeUrl: unity_root + 'WebGL.wasm.unityweb',
    cacheControl: handleCacheControl,
    productName: 'JYanna World',
    productVersion: '1.8.5',
    companyName: 'JYanna World',
  });

  React.useEffect(() => {
    if (sessionStorage.getItem('pageLoaded') === 'true') {
      sessionStorage.removeItem('pageLoaded');
      // Only block reload on IOS device
      if (IsIOS()) {
        console.log(window.location.search);
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams);

        if (urlParams.has('from')) {
          window.history.pushState({}, '', window.location.origin + PATH.UNITY);
        } else {
          window.open(window.location.origin + PATH.RELOAD, '_self');
        }
      }
    }
    globalThis.unityInstance = UNSAFE__unityInstance;
    if (globalThis.unityInstance) {
      sessionStorage.setItem('pageLoaded', 'true');
      globalThis.unitySendMessage = sendMessage;
      window.addEventListener('resize', () => {
        globalThis.unityInstance.Module.canvas.style.width =
          window.innerWidth + 'px';
        globalThis.unityInstance.Module.canvas.style.height =
          window.innerHeight + 'px';
      });
    }
  }, [UNSAFE__unityInstance]);

  return (
    <div className="container" id="unity_container">
      <Unity className="unity" tabIndex={1} unityProvider={unityProvider} />
    </div>
  );
};

export default UnityPage;
